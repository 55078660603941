<template>
  <v-dialog v-model="dialog" max-width="640" scrollable>
    <v-card>
      <v-card-title primary-title>
        <h4>Nueva Clasificación</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-2">
          <v-col cols="12" sm="4" md="4" class="py-1">
            Empresa
            <v-autocomplete v-model="data.familia" item-text="nombre" item-value="id" hide-details outlined dense
              clearable :items="familias" @change="getData()"></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4" class="py-1">
            Cuenta IIBB
            <v-autocomplete v-model="data.cuenta" item-text="PLANNOM" item-value="PLANNRO" hide-details outlined dense
              :items="cuentas" clearable></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4" class="py-1">
            Código
            <v-autocomplete v-model="data.cuenta" item-text="PLANNRO" item-value="PLANNRO" hide-details outlined dense
              :items="cuentas" clearable></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="7" class="py-1">
            Clasificación IIBB
            <v-autocomplete v-model="data.clasificacion" item-text="Clasif" item-value="Numero" hide-details outlined
              dense :items="clasificaciones" clearable></v-autocomplete>
          </v-col>
        </v-row>

        <div class="d-flex mt-10">
          <v-btn @click="dialog = false" style="margin-left: auto" color="primary">Volver</v-btn>
          <div class="ml-2">
            <BtnConfirmar @action="guardarClasificacion()" />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import BtnConfirmar from '../../util/BtnConfirmar.vue';
import FechaPicker from '../../util/FechaPicker.vue';

export default {
  data() {
    return {
      load: false,
      rangoValido: false,
      data: {
        familia: null,
        cuenta: null,
        clasificacion: null
      },

      cuentas: [],
      clasificaciones: []
    }
  },

  methods: {
    async guardarClasificacion() {
      if (!this.data.familia || !this.data.clasificacion || !this.data.cuenta) {
        this.$store.dispatch('show_snackbar', {
          text: 'Todos los campos son obligatorios.',
          color: 'warning'
        })
        return
      }

      const cuenta = this.cuentas.find(cuen => cuen.PLANNRO === this.data.cuenta)?.PLANNOM
      this.$store.state.loading = true
      await this.$store.dispatch('clasificacionVentas/nuevaClasificacionVentas', {
        cuenta,
        codigo: this.data.cuenta,
        empresa: this.data.familia,
        clasificacion: this.data.clasificacion
      }).then(res => {
        if (res.exito) {
          this.$swal.fire({
            icon: 'success',
            title: res.message
          })
          this.$emit('actualizar', res.data)
          this.dialog = false
        } else {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'error',
          })
        }
      })
      this.$store.state.loading = false
    },

    async getCuentas() {
      if (!this.data.familia) {
        this.cuentas = []
        return
      }
      this.$store.state.loading = true
      await this.$store.dispatch('clasificacionVentas/getCuentas', {
        empresa_id: this.data.familia
      }).then(res => {
        if (res.exito) {
          this.cuentas = res.data
        } else {
          
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'error'
          })
        }
      })
      this.$store.state.loading = false
    },

    async getClasificaciones() {
      this.$store.state.loading = true
      await this.$store.dispatch('clasificacionVentas/getClasificaciones', {
        empresa: this.data.familia
      }).then(res => {
        if (res.exito) {
          this.clasificaciones = res.data
        } else {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'error'
          })
        }
      })
      this.$store.state.loading = false
    },

    async getData(){
      this.data.clasificacion = []
      this.data.cuenta = []
      await this.getCuentas()
      await this.getClasificaciones()
    }
  },

  watch: {
    async dialog(val) {
      if (val) {
        this.data = {
          familia: null,
          cuenta: null,
          codigo: null,
          clasificacion: null
        }
      }
    },

  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    ...mapState(['familias']),
  },

  props: {
    value: Boolean,
  },

  created() {
    if (this.una_familia) this.data.familia = this.familias[0].id
  },

  components: {
    BtnConfirmar,
    FechaPicker
  }
}
</script>
