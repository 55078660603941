<template>
  <v-dialog v-model="dialog" max-width="640" scrollable>
    <v-card>
      <v-card-title primary-title>
        <h4>Editar Clasificación</h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-row :no-gutters="$vuetify.breakpoint.xs" class="mt-2">
          <v-col cols="12" sm="4" md="4" class="py-1">
            Empresa
            <v-autocomplete readonly disabled v-model="data.familia" item-text="nombre" item-value="id" hide-details
              outlined dense clearable :items="familias" @change="getCuentas"></v-autocomplete>
          </v-col>
          <v-col cols="6" sm="5" xl="5" class="py-1">
            Cuenta IIBB
            <v-text-field readonly disabled v-model="data.cuenta" hide-details outlined dense></v-text-field>
          </v-col>
          <v-col cols="6" sm="3" xl="2" class="py-1">
            Código
            <v-text-field readonly disabled v-model="data.codigo" hide-details outlined dense
              type="number"></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="7" class="py-1">
            Clasificación IIBB
            <v-autocomplete v-model="data.clasificacion" item-text="Clasif" item-value="Numero" hide-details outlined
              dense :items="clasificaciones"></v-autocomplete>
          </v-col>
        </v-row>

        <div class="d-flex mt-10">
          <v-btn @click="dialog = false" style="margin-left: auto" color="primary">Volver</v-btn>
          <div class="ml-2">
            <BtnConfirmar @action="guardarClasificacion()" />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import BtnConfirmar from '../../util/BtnConfirmar.vue';
import FechaPicker from '../../util/FechaPicker.vue';

export default {
  data() {
    return {
      load: false,
      rangoValido: false,
      data: {
        familia: null,
        cuenta: null,
        codigo: null,
        clasificacion: null
      },
      cuentas: [],
      clasificaciones: []
    }
  },

  methods: {
    async guardarClasificacion() {
      if (!this.data.clasificacion) {
        this.$store.dispatch('show_snackbar', {
          text: 'El campo "Clasificación" es obligatorio.',
          color: 'warning'
        })
        return
      }

      await this.$store.dispatch('clasificacionVentas/editarClasificacionVentas', {
        clasificacion: this.data.clasificacion,
        empresa: this.clasificacion.Empresa_id,
        preClasif: this.clasificacion.Clasif,
        codigo: this.clasificacion.PLANNRO,
        id: this.clasificacion.Id
      }).then(res => {
        if (res.exito) {
          this.$swal.fire({
            icon: 'success',
            title: res.message
          })
          
          this.$emit('actualizar', {
            id: this.clasificacion.Id,
            clasificacion: this.data.clasificacion,
            nombre_clasif: this.clasificaciones.find(clas => clas.Id === this.data.clasificacion)?.Clasificacion
          })
          this.dialog = false

          // ACTUALIZAR EL VALOR DE LA CLASIFICACION EDITADA
        } else {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'error'
          })
        }
      })

    },

    async getCuentas() {
      if (!this.data.familia) {
        this.cuentas = []
        return
      }
      this.$store.state.loading = true
      await this.$store.dispatch('clasificacionCompras/getCuentas', {
        empresa_id: this.data.familia
      }).then(res => {
        if (res.exito) {
          this.cuentas = res.data
        } else {
          this.$store.dispatch('show_snackbar', {
            text: res.message,
            color: 'error'
          })
        }
      })
      this.$store.state.loading = false
    }
  },

  watch: {
    async dialog(val) {
      if (val) {
        this.data.familia = this.clasificacion.Empresa_id

        await this.$store.dispatch('clasificacionVentas/getClasificaciones', {
          empresa: this.clasificacion.Empresa_id
        }).then(res => {
          if (res.exito) {
            this.clasificaciones = res.data
          } else {
            this.$store.dispatch('show_snackbar', {
              text: res.message,
              color: 'error'
            })
          }
        })

        this.data = {
          familia: this.clasificacion.Empresa_id,
          cuenta: this.clasificacion.Cuenta,
          codigo: this.clasificacion.PLANNRO,
          clasificacion: this.clasificacion.Clasif
        }

      } else {
        this.data = {
          familia: null,
          cuenta: null,
          codigo: null,
          clasificacion: null
        }
      }
    },

  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    ...mapState(['familias']),
  },

  props: {
    value: Boolean,
    clasificacion: {
      type: Object,
      default: {}
    }
  },

  created() {
    if (this.una_familia) this.data.familia = this.familias[0].id
  },

  components: {
    BtnConfirmar,
    FechaPicker
  }
}
</script>
